import { useEffect, useMemo, useState } from 'react'

import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { PieChart } from '@mui/x-charts/PieChart'

import IMotivation from '../../interfaces/iMotivation'
import styles from './results.module.css'
import axios from 'axios'
import IFormCode from '../../interfaces/iFormCode'

import { usePDF } from 'react-to-pdf'

const Results = ({
  motivation,
  saveFlag,
}: {
  motivation: Array<IMotivation>
  saveFlag: boolean
}) => {
  const [countryResults, setCountryResults] = useState<Array<any>>()
  const [softHabilities, setSoftHabilities] = useState()
  const [hardHabilities, setHardHabilities] = useState()
  const [talentsList, setTalentList] = useState<Array<any>>()
  const [mobileTalentsList, setMobileTalentList] = useState<Array<any>>()
  const [pieChardData, setPieChart] = useState<Array<any>>()
  const [flagValue, setFlagValues] = useState<Array<IMotivation>>()
  const [dinamycTalentNames, setDinamycTalentNames] = useState<Array<string>>()
  const [saveUserData, setSaveUserData] = useState<IFormCode>()

  // Ref
  const { toPDF, targetRef } = usePDF({ filename: 'results.pdf' })

  const returnImageName = (imageName: string) => {
    let formatteImageName = ''
    switch (imageName) {
      case 'Diversión':
        formatteImageName = 'diversion'
        break
      case 'Perfección':
        formatteImageName = 'perfeccion'
        break
      default:
        formatteImageName = imageName?.toLowerCase()
    }

    return formatteImageName
  }

  const talentColorList: {
    [key: string]: string
  } = useMemo(
    () => ({
      Relacional: '#2890DD',
      Tareas: '#21AB4F',
      Creativo: '#FBD255',
      Liderazgo: '#FAA220',
      Animador: '#D64141',
    }),
    [],
  )

  const talentsNamesList = useMemo(
    () => ['Relacional', 'Tareas', 'Creativo', 'Liderazgo', 'Animador'],
    [],
  )
  const flagIndice = useMemo(() => ['A', 'B', 'C', 'D', 'E'], [])

  const returnColor = (talent: string) => {
    return talentColorList[talent]
  }

  // Use Effect
  useEffect(() => {
    // Country Calification
    const countryCalification = () => {
      const result = motivation?.reduce((acc: Array<any>, currentValue: IMotivation) => {
        const country = currentValue.country
        const calification = currentValue.calification || 0

        const foundCountryIndex = acc.findIndex((item) => item.country === country)

        if (foundCountryIndex !== -1) {
          acc[foundCountryIndex].calification += calification
        } else {
          acc.push({ country, calification })
        }

        return acc
      }, [])

      result?.sort((a, b) => b.calification - a.calification)
      return result
    }
    // Habilities
    const habilities = () => {
      const result = motivation?.reduce((acc: Array<any>, currentValue: IMotivation) => {
        const clasification = currentValue.clasification
        const calification = currentValue.calification || 0

        const foundCountryIndex = acc.findIndex((item) => item.clasification === clasification)

        if (foundCountryIndex !== -1) {
          acc[foundCountryIndex].calification += calification
        } else {
          acc.push({ clasification, calification })
        }

        return acc
      }, [])

      const filterResult = result?.filter((ele) => ele.clasification !== 'none')

      const hardHabilities = filterResult?.filter((ele) => ele.clasification === 'Duro')
      const softHabilities = filterResult?.filter((ele) => ele.clasification === 'Suave')
      setHardHabilities(hardHabilities ? hardHabilities[0]?.calification : 0)
      setSoftHabilities(softHabilities ? softHabilities[0]?.calification : 0)
    }

    // Set Pie Chart
    const pieChart = () => {
      const getRatedMotivationList = motivation?.filter(
        (ele) => ele?.calification && ele?.calification > 6,
      )

      const talentsNames = Array.from(new Set(motivation?.map((item) => item.talent)))

      setDinamycTalentNames(talentsNames)

      const matriz: any = []

      talentsNames.forEach((ele) => {
        const temporalArray: any = []
        temporalArray.push(ele)
        getRatedMotivationList?.forEach((mot: IMotivation) => {
          if (mot.talent === ele) {
            temporalArray.push(mot.name)
          }
        })

        matriz.push(temporalArray)
      })

      let pieChartData: any = []
      const getMaxValue = getRatedMotivationList.length
      matriz.forEach((ele: any, index: any) => {
        const tempChartRow = {
          id: index,
          value: Math.round(((ele.length - 1) * 100) / getMaxValue),
          label: ele[0],
          color: talentColorList[ele[0]],
        }
        pieChartData.push(tempChartRow)
      })

      const filteredPieChart = pieChartData?.filter((item: { value: number }) => item.value !== 0)

      setPieChart(filteredPieChart)
    }

    // talents
    const talents = () => {
      const getRatedMotivationList = motivation?.filter(
        (ele) => ele?.calification && ele?.calification > 6,
      )

      const matriz: any = []

      talentsNamesList.forEach((ele) => {
        const temporalArray: any = []
        temporalArray.push(ele)
        getRatedMotivationList?.forEach((mot: IMotivation) => {
          if (mot.talent === ele) {
            temporalArray.push(mot.name)
          }
        })

        matriz.push(temporalArray)
      })

      setMobileTalentList(matriz)

      const maxLength = matriz.reduce((max: number, row: any[]) => Math.max(max, row.length), 0)

      matriz.forEach((row: any[]) => {
        while (row.length < maxLength) {
          row.push('empty')
        }
      })

      const headers = matriz.map((row: any) => row[0])

      const newMatriz = []
      newMatriz.push(headers)

      if (matriz && matriz?.length !== 0) {
        for (let i = 1; i < matriz[0].length; i++) {
          const columna = matriz.map((row: any) => row[i])
          newMatriz.push(columna)
        }
      }

      return newMatriz
    }

    // Get Flag Value
    const flagValues = () => {
      const flagValue = motivation?.filter((flag) => flag.sortOrder)

      flagValue?.sort((b, a) => parseInt(b.sortOrder) - parseInt(a.sortOrder))
      return flagValue
    }

    const recordFormData = () => {
      const userDataSaved = sessionStorage.getItem('userData') ?? ''
      const userData = JSON.parse(userDataSaved)
      const formData = JSON.stringify(motivation)

      setSaveUserData(userData)

      const body = { resultsData: formData, idCode: userData?.idCode }

      axios({
        method: 'post',
        url: 'https://g6xcm6e3g8.execute-api.us-east-2.amazonaws.com/dev/saveFormsResults',
        data: JSON.stringify(body),
      })
        .then(() => {
          sessionStorage.removeItem('userData')
        })
        .catch(() => {})
    }

    pieChart()
    setCountryResults(countryCalification())
    setTalentList(talents())
    setFlagValues(flagValues)
    habilities()

    if (saveFlag) {
      recordFormData()
    }
  }, [motivation, talentColorList, talentsNamesList, saveFlag])

  return (
    <>
      <Grid item container justifyContent="right" paddingY="5px">
        <Button
          className={styles.submitButton}
          color="success"
          size="large"
          type="submit"
          onClick={() => toPDF()}
          variant="contained">
          Descargar Resultados
        </Button>
      </Grid>
      <Grid item container sx={{ marginTop: { sx: '0', md: '-50px' } }} ref={targetRef}>
        <Typography
          component="h1"
          variant="h1"
          sx={{
            fontSize: { xs: '36px', md: '56px' },
            padding: { xs: '10px 10px 30px', md: '35px 40px' },
          }}>
          Resultados
        </Typography>
        {/*  Country Section */}
        <Grid
          item
          container
          xs={23}
          sx={{
            padding: { xs: '20px 10px', md: '20px 40px' },
          }}>
          <Typography component="h2" variant="h2" sx={{ fontSize: { xs: '24px', md: '34px' } }}>
            País:
          </Typography>
          <Grid item container xs={12}>
            <Grid item xs={12} md={4} padding="30px 0">
              <Box
                sx={{
                  width: { xs: '100%', md: '90%' },
                  borderRadius: '10px',
                  backgroundColor: '#FCF9F7',
                  boxSizing: 'border-box',
                }}
                padding="10px">
                {countryResults && countryResults[0] && (
                  <Box
                    component="img"
                    width="100%"
                    src={`./${returnImageName(countryResults[0]?.country)}.png`}></Box>
                )}
                {countryResults && (
                  <Box
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '24px',
                      fontWeight: 700,
                      color: '#5B5B5B',
                      width: '100%',
                      padding: '10px',
                      textAlign: 'center',
                    }}>
                    {countryResults[0]?.calification} Puntos
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid container item xs={12} md={4} justifyContent="center" alignItems="center">
              {countryResults && (
                <Box
                  sx={{
                    width: { xs: '70%', md: '60%' },
                    borderRadius: '10px',
                    backgroundColor: '#FCF9F7',
                  }}
                  padding="10px">
                  {countryResults && (
                    <Box
                      border="1px solid #00000033"
                      component="img"
                      width="100%"
                      src={`./${returnImageName(countryResults[1]?.country)}.png`}></Box>
                  )}
                  {countryResults && (
                    <Box
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '24px',
                        fontWeight: 700,
                        color: '#5B5B5B',
                        width: '100%',
                        padding: '10px',
                        textAlign: 'center',
                      }}>
                      {countryResults[1]?.calification} Puntos
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignContent="center"
              xs={12}
              md={4}
              sx={{ paddingTop: { xs: '20px', md: '0' } }}>
              {countryResults && countryResults.length > 2 && (
                <Grid item xs={12} md={10} sx={{ backgroundColor: 'white' }}>
                  <Grid
                    container
                    item
                    justifyItems="center"
                    alignItems="center"
                    borderRadius="12px"
                    border="1px solid #00000033">
                    {countryResults.map((element, index) => {
                      return (
                        index > 1 && (
                          <Grid container item key={index}>
                            <Grid
                              item
                              xs={6}
                              fontSize="16px"
                              fontWeight="700"
                              borderRight="1px solid #00000033"
                              borderBottom={
                                index + 1 === countryResults.length ? '0px' : '1px solid #00000033'
                              }
                              sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: '700' }}
                              padding="20px">
                              {element.country}
                            </Grid>

                            <Grid
                              item
                              xs={6}
                              fontSize="16px"
                              width="50%"
                              padding="20px"
                              sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: '500' }}
                              borderBottom={
                                index + 1 === countryResults.length ? '0px' : '1px solid #00000033'
                              }>
                              {element.calification} Puntos
                            </Grid>
                          </Grid>
                        )
                      )
                    })}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/*  Separator Section */}
        <Grid
          item
          xs={12}
          sx={{
            border: '0',
            padding: { xs: '15px 0', md: '45px 0' },
            borderTop: '2px solid #B7B4B480',
          }}></Grid>
        {/*  Habilities Section */}
        <Grid
          item
          container
          xs={23}
          sx={{
            borderTop: '2px solid #B7B4B480',
            padding: { xs: '20px 10px', md: '20px 40px' },
          }}>
          <Typography component="h2" variant="h2" sx={{ fontSize: { xs: '24px', md: '34px' } }}>
            Habilidades blandas y duras:
          </Typography>
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignContent="center"
              sx={{ padding: { xs: '30px 0', md: '10px 0' } }}>
              <Box
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '28px',
                  fontWeight: '900',
                  width: '75px',
                  padding: '21px 0',
                  textAlign: 'center',
                  verticalAlign: 'center',
                  border: `4px solid ${
                    softHabilities === hardHabilities
                      ? '#000'
                      : softHabilities > hardHabilities
                        ? '#D62748'
                        : '#00ABD5'
                  }`,
                  color:
                    softHabilities === hardHabilities
                      ? '#000'
                      : softHabilities > hardHabilities
                        ? '#D62748'
                        : '#00ABD5',
                  borderRadius: '50%',
                }}>
                {softHabilities && hardHabilities ? Math.abs(softHabilities - hardHabilities) : 0}
              </Box>
            </Grid>
            <Grid item xs={6} textAlign="right" paddingRight="30px">
              <Box
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '28px',
                  fontWeight: '700',
                  color: '#D62748',
                  padding: '10px 0 20px',
                }}>
                Blandas
              </Box>
              <Box component="img" src="./leftHeart.png" />
              <Box
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '24px',
                  fontWeight: '700',
                  color: '#D62748',
                }}>
                {softHabilities && softHabilities} Puntos
              </Box>
            </Grid>
            <Grid item xs={6} textAlign="left" paddingLeft="30px">
              <Box
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '28px',
                  fontWeight: '700',
                  color: '#00ABD5',
                  padding: '10px 0 20px',
                }}>
                Duras
              </Box>
              <Box component="img" src="./rightBrain.png" />
              <Box
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '24px',
                  fontWeight: '700',
                  color: '#00ABD5',
                }}>
                {hardHabilities && hardHabilities} Puntos
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/*  Separator Section */}
        <Grid
          item
          xs={12}
          sx={{
            border: '0',
            padding: { xs: '15px 0', md: '45px 0' },
            borderTop: '2px solid #B7B4B480',
          }}></Grid>
        <Grid
          item
          container
          xs={23}
          sx={{
            borderTop: '2px solid #B7B4B480',
            padding: { xs: '20px 10px', md: '20px 40px' },
          }}>
          <Typography component="h2" variant="h2" sx={{ fontSize: { xs: '24px', md: '34px' } }}>
            Motivaciones:
          </Typography>

          <Grid container item xs={12} padding="60px 0" justifyContent="center" position="relative">
            <Grid container item xs={12} justifyContent="center">
              {/* Flag B */}
              <Grid
                item
                container
                justifyContent="right"
                alignContent="end"
                xs={6}
                padding="5px"
                position="relative"
                sx={{ minHeight: { xs: '20vw', md: '305px' } }}>
                <Grid
                  className={styles.leftTop}
                  bgcolor={flagValue && returnColor(flagValue[1]?.talent ?? '')}
                  item
                  container
                  position="relative"
                  sx={{
                    width: { xs: '80%', md: '435px' },
                    height: { xs: '100%', md: '270px' },
                  }}></Grid>
                <Box
                  component="span"
                  position="absolute"
                  sx={{
                    fontSize: '34px',
                    fontWeight: '700',
                    fontFamily: 'Roboto',
                    color: 'white',
                    bottom: { xs: '48%', md: '15px' },
                    right: { xs: '63%', md: '390px' },
                  }}>
                  B
                </Box>
                {flagValue && flagValue[1] && (
                  <Grid
                    item
                    container
                    position="absolute"
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                    width="450px"
                    right="0"
                    top="40px"
                    padding="20px 40px"
                    justifyContent="center">
                    <Box sx={{ fontFamily: 'Roboto', fontSize: '34px', fontWeight: '700' }}>
                      {flagValue[1].name}
                    </Box>
                    <Box
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        fontWeight: '500',
                        padding: '10px',
                        textAlign: 'center',
                      }}>
                      {flagValue[1].characteristics}
                    </Box>
                  </Grid>
                )}
              </Grid>
              {/* Flag C */}
              <Grid
                item
                container
                justifyContent="left"
                alignContent="end"
                xs={6}
                padding="5px"
                position="relative"
                sx={{ minHeight: { xs: '20vw', md: '305px' } }}>
                <Grid
                  className={styles.rightTop}
                  bgcolor={flagValue && returnColor(flagValue[2]?.talent ?? '')}
                  item
                  container
                  position="relative"
                  sx={{
                    width: { xs: '80%', md: '455px' },
                    height: { xs: '100%', md: '285px' },
                  }}></Grid>
                <Box
                  component="span"
                  position="absolute"
                  sx={{
                    fontSize: '34px',
                    fontWeight: '700',
                    fontFamily: 'Roboto',
                    color: 'white',
                    bottom: { xs: '48%', md: '15px' },
                    left: { xs: '63%', md: '400px' },
                  }}>
                  C
                </Box>
                {flagValue && flagValue[2] && (
                  <Grid
                    item
                    container
                    position="absolute"
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                    width="450px"
                    left="0"
                    top="60px"
                    padding="20px 40px"
                    justifyContent="center">
                    <Box sx={{ fontFamily: 'Roboto', fontSize: '34px', fontWeight: '700' }}>
                      {flagValue[2].name}
                    </Box>
                    <Box
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        fontWeight: '500',
                        padding: '10px',
                        textAlign: 'center',
                      }}>
                      {flagValue[2].characteristics}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              {/* Flag D */}
              <Grid
                item
                container
                justifyContent="right"
                alignContent="flex-start"
                xs={6}
                padding="5px"
                position="relative"
                sx={{ minHeight: { xs: '20vw', md: '305px' } }}>
                <Grid
                  className={styles.leftBottom}
                  bgcolor={flagValue && returnColor(flagValue[3]?.talent ?? '')}
                  item
                  container
                  position="relative"
                  sx={{
                    width: { xs: '80%', md: '423px' },
                    height: { xs: '100%', md: '305px' },
                  }}></Grid>
                <Box
                  component="span"
                  position="absolute"
                  sx={{
                    fontSize: '34px',
                    fontWeight: '700',
                    fontFamily: 'Roboto',
                    color: 'white',
                    top: { xs: '48%', md: '15px' },
                    right: { xs: '63%', md: '390px' },
                  }}>
                  D
                </Box>
                {flagValue && flagValue[3] && (
                  <Grid
                    item
                    container
                    position="absolute"
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                    width="450px"
                    right="0"
                    top="120px"
                    padding="20px 40px"
                    justifyContent="center">
                    <Box sx={{ fontFamily: 'Roboto', fontSize: '34px', fontWeight: '700' }}>
                      {flagValue[3].name}
                    </Box>
                    <Box
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        fontWeight: '500',
                        padding: '10px',
                        textAlign: 'center',
                      }}>
                      {flagValue[3].characteristics}
                    </Box>
                  </Grid>
                )}
              </Grid>
              {/* Flag E */}
              <Grid
                item
                container
                justifyContent="left"
                alignContent="flex-start"
                xs={6}
                padding="5px"
                position="relative"
                sx={{ minHeight: { xs: '20vw', md: '305px' } }}>
                <Grid
                  className={styles.rightBottom}
                  bgcolor={flagValue && returnColor(flagValue[4]?.talent ?? '')}
                  item
                  container
                  position="relative"
                  sx={{
                    width: { xs: '80%', md: '448px' },
                    height: { xs: '100%', md: '295px' },
                  }}></Grid>
                <Box
                  component="span"
                  position="absolute"
                  zIndex="3"
                  sx={{
                    fontSize: '34px',
                    fontWeight: '700',
                    fontFamily: 'Roboto',
                    color: 'white',
                    top: { xs: '48%', md: '15px' },
                    left: { xs: '63%', md: '400px' },
                  }}>
                  E
                </Box>
                {flagValue && flagValue[4] && (
                  <Grid
                    item
                    container
                    position="absolute"
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                    width="450px"
                    left="0"
                    top="120px"
                    padding="20px 40px"
                    justifyContent="center">
                    <Box sx={{ fontFamily: 'Roboto', fontSize: '34px', fontWeight: '700' }}>
                      {flagValue[4].name}
                    </Box>
                    <Box
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        fontWeight: '500',
                        padding: '10px',
                        textAlign: 'center',
                      }}>
                      {flagValue[4].characteristics}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* Flag A */}
            <Grid
              container
              item
              position="absolute"
              justifyContent="center"
              alignContent="center"
              bgcolor={flagValue && returnColor(flagValue[0]?.talent ?? '')}
              textAlign="center"
              zIndex="5"
              sx={{
                left: '50%',
                top: '50%',
                border: { xs: '10px solid white', md: '20px solid white' },
                transform: 'translate(-50%, -50%)',
                width: { xs: '40%', md: '600px' },
                height: { xs: '25%', md: '200px' },
              }}>
              <Box
                component="span"
                position="absolute"
                sx={{
                  fontSize: '34px',
                  fontWeight: '700',
                  fontFamily: 'Roboto',
                  color: 'white',
                  top: { xs: 'calc(47% - 20px)', md: '15px' },
                  left: { xs: 'calc(48% - 11px)', md: '4%' },
                }}>
                A
              </Box>
              {flagValue && flagValue[0] && (
                <Grid sx={{ display: { xs: 'none', md: 'block' } }}>
                  <Box sx={{ fontFamily: 'Roboto', fontSize: '34px', fontWeight: '700' }}>
                    {flagValue[0].name}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '20px',
                      fontWeight: '500',
                      padding: '10px',
                    }}>
                    {flagValue[0].characteristics}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ display: { xs: 'flex', md: 'none' } }}>
            {flagValue &&
              flagValue.map((flag, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    bgcolor={returnColor(flagValue[index]?.talent ?? '')}
                    marginBottom="20px"
                    padding="15px"
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '22px',
                      color: 'white',
                      fontWeight: '500',
                    }}>
                    <Box padding="2px 0">{flagIndice[index]}</Box>
                    <Box padding="2px 0">{flag.name}</Box>
                    <Box sx={{ fontWeight: '400' }} padding="2px 0">
                      {flag.characteristics}
                    </Box>
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
        {/*  Separator Section */}
        <Grid
          item
          xs={12}
          sx={{
            border: '0',
            padding: { xs: '15px 0', md: '45px 0' },
            borderTop: '2px solid #B7B4B480',
          }}></Grid>
        <Grid
          item
          container
          xs={23}
          sx={{
            borderTop: '2px solid #B7B4B480',
            padding: { xs: '20px 10px', md: '20px 40px' },
          }}>
          <Typography component="h2" variant="h2" sx={{ fontSize: { xs: '24px', md: '34px' } }}>
            Expresión y Talentos
          </Typography>
          <Grid
            item
            container
            columns={14}
            sx={{ display: { xs: 'none', md: 'flex' } }}
            padding="55px 0">
            {talentsList &&
              talentsList.map((talent, index) => {
                return (
                  <Grid container item xs={12} justifyContent="center" key={index}>
                    {talent.map((tal: any, key: number) => {
                      return (
                        <Grid
                          item
                          xs={2}
                          bgcolor={
                            tal === 'empty' ? 'white' : talentColorList[talentsNamesList[key]]
                          }
                          padding="20px"
                          borderRadius={
                            index === 0 && key === 0
                              ? '12px 0 0 0'
                              : index === 0 && key + 1 === talent?.length
                                ? '0 12px 0 0'
                                : index + 1 === talentsList?.length && key === 0
                                  ? '0 0 0 12px'
                                  : index + 1 === talentsList?.length && key + 1 === talent.length
                                    ? '0 0 12px 0'
                                    : ''
                          }
                          borderLeft={key === 0 ? '1px solid #00000033' : ''}
                          borderTop={index === 0 ? '1px solid #00000033' : ''}
                          borderRight="1px solid #00000033"
                          borderBottom="1px solid #00000033"
                          textAlign="center"
                          sx={
                            index === 0
                              ? { fontSize: '16px', fontWeight: '700', fontFamily: 'Inter' }
                              : { fontSize: '16px', fontWeight: '500', fontFamily: 'Inter' }
                          }
                          key={key}>
                          {tal === 'empty' ? '' : tal}
                        </Grid>
                      )
                    })}
                  </Grid>
                )
              })}
          </Grid>
          <Grid
            item
            container
            columns={14}
            sx={{ display: { xs: 'flex', md: 'none' } }}
            padding="55px 0"
            justifyContent="center">
            {mobileTalentsList &&
              mobileTalentsList.map((talent, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    bgcolor={talentColorList[talentsNamesList[index]]}
                    xs={11}
                    justifyContent="center"
                    border="1px solid #00000033"
                    borderTop="0"
                    borderRadius="12px"
                    marginBottom="20px">
                    {talent &&
                      talent.map((row: any, key: any) => {
                        return (
                          row !== 'empty' && (
                            <Grid
                              padding="20px"
                              borderTop={key === 0 ? '' : '1px solid #00000033'}
                              sx={
                                key === 0
                                  ? { fontSize: '16px', fontWeight: '700', fontFamily: 'Inter' }
                                  : { fontSize: '16px', fontWeight: '500', fontFamily: 'Inter' }
                              }
                              key={key}>
                              {row}
                            </Grid>
                          )
                        )
                      })}
                  </Grid>
                )
              })}
          </Grid>
          <Typography component="h3" variant="h2" fontSize="24px">
            Descripción de Talentos:
          </Typography>
          <Grid
            item
            container
            columns={14}
            sx={{ padding: { xs: '10px 0', md: '55px 0' } }}
            justifyContent="center">
            {talentsList &&
              talentsList[0].map((talent: any, index: any) => {
                let first: number = 0
                return (
                  <Grid
                    item
                    container
                    key={index}
                    xs={14}
                    justifyContent="center"
                    sx={{ padding: { xs: '10px 0', md: '20px 0' } }}>
                    {motivation?.map((mov, key) => {
                      first = mov.talent === talent ? first + 1 : first

                      return (
                        mov.talent === talent && (
                          <Grid
                            item
                            container
                            xs={12}
                            md={10}
                            justifyContent="center"
                            sx={{ fontSize: '16px', fontWeight: '500', fontFamily: 'Inter' }}
                            key={key}>
                            <Grid
                              item
                              xs={5}
                              md={3}
                              bgcolor={dinamycTalentNames && talentColorList[mov.talent]}
                              borderRadius={first === 1 ? '12px 0 0 0' : ''}
                              padding="20px"
                              borderLeft="1px solid #00000033"
                              borderBottom="1px solid #00000033"
                              borderTop={first === 1 ? '1px solid #00000033' : ''}>
                              {mov.name}
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              md={8}
                              padding="20px"
                              borderRadius={first === 1 ? '0 12px 0 0' : ''}
                              borderLeft="1px solid #00000033"
                              borderRight="1px solid #00000033"
                              borderBottom="1px solid #00000033"
                              borderTop={first === 1 ? '1px solid #00000033' : ''}>
                              {mov.description}
                            </Grid>
                          </Grid>
                        )
                      )
                    })}
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
        {/*  Separator Section */}
        <Grid
          item
          xs={12}
          sx={{
            border: '0',
            padding: { xs: '15px 0', md: '45px 0' },
            borderTop: '2px solid #B7B4B480',
          }}></Grid>
        <Grid
          item
          container
          xs={23}
          sx={{
            borderTop: '2px solid #B7B4B480',
            padding: { xs: '20px 10px', md: '20px 40px' },
          }}>
          <Typography component="h2" variant="h2" sx={{ fontSize: { xs: '24px', md: '34px' } }}>
            Como {saveUserData?.name} triunfa en la vida:
          </Typography>
          <Grid container item xs={12} sx={{ padding: { md: '80px 0' } }}>
            {pieChardData && (
              <PieChart
                series={[
                  {
                    arcLabel: (item) => `${item.value}%`,
                    data: pieChardData,
                  },
                ]}
                width={650}
                height={400}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Results
