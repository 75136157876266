import { Box, Typography } from '@mui/material'

import Icon from '../Icon/icon'

const IconMessage = ({ text, className, icon }: any) => {
  return (
    <Typography className={className} component="p" variant="subtitle2" alignContent="center">
      {icon && <Icon name="ErrorOutline" />}
      <Box component="span" paddingLeft="5px">
        {text}
      </Box>
    </Typography>
  )
}

export default IconMessage
