import { useEffect, useState } from 'react'

import { Box, Grid, Slider } from '@mui/material'

import IMotivation from '../../interfaces/iMotivation'
import styles from './motivationSlider.module.css'

const MotivationSlider = ({
  motivation,
  formArray,
  setFormArray,
}: {
  motivation: IMotivation
  formArray: Array<IMotivation>
  setFormArray: any
}) => {
  const [currentValue, setCurrentValue] = useState(0)

  const sliderHandler = (event: any) => {
    const calification = event?.target?.value ?? 0
    const temporalFormArray = formArray.map((ele) => {
      if (ele.id === motivation.id) {
        ele.calification = calification
      }
      return ele
    })

    setFormArray(temporalFormArray)
  }

  // Use Effect
  useEffect(() => {
    setCurrentValue(motivation?.calification ?? 0)
  }, [motivation?.calification])

  return (
    <Grid item xs={12} md={3} className={styles.motivationLabel} padding="10px">
      <Grid
        item
        container
        border="1px solid #EFEFEF"
        boxShadow="0px 0px 5px 2px #00000005"
        borderRadius="5px"
        padding="20px"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item>{motivation?.name}</Grid>
        <Box width="100%" padding="15px 0">
          {motivation.description}
        </Box>
        <Slider
          aria-label="Puntuación"
          value={currentValue}
          valueLabelDisplay="auto"
          shiftStep={1}
          step={1}
          marks
          min={0}
          max={10}
          onChange={sliderHandler}
          sx={{ color: '#2196F3' }}
        />
        <Grid item container justifyContent="space-between">
          <Box component="span" className={styles.scale}>
            0
          </Box>
          <Box component="span" className={styles.scale}>
            10
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MotivationSlider
