import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const NoResults = () => {
  return (
    <Grid container justifyContent="center" textAlign="center" padding="100px 25px">
      <Typography component="h3" variant="h3">
        Lo sentimos.
        <br />
        No pudimos encontrar una coincidencia.
      </Typography>
      <img width="300" alt="No results" src="./noresults.png" />
    </Grid>
  )
}

export default NoResults
