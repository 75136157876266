import { Box, Grid } from '@mui/material'

import styles from './loginImage.module.css'

const LoginImage = () => {
  return (
    <Grid
      className={styles.bgImage}
      container
      item
      sm={6}
      xs={12}
      sx={{ display: { xs: 'block', sm: 'none', md: 'block' } }}>
      <Box
        component="img"
        src="loginbackground.png"
        width="100%"
        sx={{ display: { xs: 'block', sm: 'none' } }}></Box>
    </Grid>
  )
}

export default LoginImage
