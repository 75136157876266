import moment from 'moment'
import { useState } from 'react'

import { Button, CircularProgress } from '@mui/material'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Box from '@mui/system/Box'

import IDataRow from '../../interfaces/iDataRow'
import IDataTable from '../../interfaces/iDataTable'
import styles from './dataTable.module.css'
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'

const DataTable = ({ dataTable }: IDataTable) => {
  const headerTitles = ['Nombre', 'Email', 'Utilizado', 'Emisión', 'Expira']
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState<IDataRow>()
  const [loading, setLoading] = useState(false)
  const [emailSend, setEmailSend] = useState(false)

  const formatDate = (date: string) => {
    var momentDate = moment(date)
    return momentDate.format('DD/MM/YYYY')
  }

  const rowHandler = (rowData: IDataRow) => {
    setOpen(true)
    setModalData(rowData)
  }

  const handleClose = () => {
    setEmailSend(false)
    setOpen(false)
  }

  const renderStatus = (status: number, expiredDate: string) => {
    const today = moment()
    const expired = moment(expiredDate)

    if (today.isAfter(expired)) {
      return <span className={`${styles.status} ${styles.expirado}`}>Expirado</span>
    } else if (status === 0) {
      return <span className={`${styles.status} ${styles.sinActivar}`}>Sin activar</span>
    } else if (status === 1) {
      return <span className={`${styles.status} ${styles.activado}`}>Activado</span>
    } else if (status === 2) {
      return <span className={`${styles.status} ${styles.completado}`}>Completado</span>
    } else if (status === 3) {
      return <span className={`${styles.status} ${styles.expirado}`}>Expirado</span>
    }
  }

  const handleCodeEvent = async (data: IDataRow) => {
    setLoading(true)
    const body = { idCode: data.idCode, status: data.status }
    try {
      await axios({
        method: 'post',
        url: 'https://g6xcm6e3g8.execute-api.us-east-2.amazonaws.com/dev/resendCodeEmail',
        data: JSON.stringify(body),
      })
      setEmailSend(true)
      setLoading(false)
      return true
    } catch (error) {
      return false
      setLoading(false)
    }
  }

  return (
    <Grid container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box className={styles.modal}>
          <Grid container columns={4} padding="0 10px 0">
            <Grid item xs={4} padding="20px 0 0 10px">
              <span className={styles.modalTitle}>{modalData?.name}</span>
            </Grid>
            <Grid item xs={4} padding="0 0 15px 10px">
              <span className={styles.modalSubtitle}>{modalData?.email}</span>
            </Grid>
            <Grid item className={styles.modalRowTitle} xs={2} padding="12px 0 0 10px">
              <span>Estado</span>
            </Grid>
            <Grid item xs={2} padding="12px 10px" textAlign="right">
              <span>{renderStatus(modalData?.status ?? 0, modalData?.expired ?? '')}</span>
            </Grid>
            <Grid item className={styles.modalRowTitle} xs={2} padding="10px 0 0 10px">
              <span>Fecha de Creación</span>
            </Grid>
            <Grid
              item
              className={styles.modalRowTitle}
              xs={2}
              padding="12px 10px"
              textAlign="right">
              <span>{formatDate(modalData?.created ?? '')}</span>
            </Grid>
            <Grid item className={styles.modalRowTitle} xs={2} padding="10px 0 0 10px">
              <span>Fecha de Vencimiento</span>
            </Grid>
            <Grid
              item
              className={styles.modalRowTitle}
              xs={2}
              padding="12px 10px"
              textAlign="right">
              <span>{formatDate(modalData?.expired ?? '')}</span>
            </Grid>
            <Grid xs={4} item textAlign="right">
              <LoadingButton
                loading={loading}
                size="large"
                type="submit"
                variant="text"
                onClick={() => {
                  handleCodeEvent(modalData as IDataRow)
                }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <span className={styles.leftPanelText}>
                    <span className={styles.leftPanelTextButton}>
                      {modalData?.status === 2 ? 'Enviar resultado' : 'Reenviar Código'}
                    </span>
                  </span>
                )}
              </LoadingButton>
              {emailSend && (
                <div className={styles.leftPanelText}>
                  <span className={styles.leftPanelTextButton}>Email Enviado correctamente</span>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Grid container columns={10} padding="0 0 0 20px">
        {headerTitles &&
          headerTitles.map((row, index) => {
            return (
              <Grid
                className={styles.header}
                color="primary.main"
                item
                xs={2}
                padding="20px"
                borderBottom="1px solid #ddd"
                key={index}>
                {row}
              </Grid>
            )
          })}
      </Grid>
      {dataTable?.map((row: IDataRow, index) => {
        return (
          !row.hide && (
            <Grid
              container
              columns={10}
              padding="0 0 0 20px"
              key={index}
              onClick={() => {
                rowHandler(row)
              }}>
              <Grid
                className={styles.data}
                item
                xs={2}
                padding="20px"
                borderBottom="1px solid #ddd">
                {row.name}
              </Grid>
              <Grid
                className={styles.data}
                item
                xs={2}
                padding="20px"
                borderBottom="1px solid #ddd">
                {row.email}
              </Grid>
              <Grid
                className={styles.data}
                item
                xs={2}
                padding="20px"
                borderBottom="1px solid #ddd">
                {renderStatus(row.status, row.expired)}
              </Grid>
              <Grid
                className={styles.data}
                item
                xs={2}
                padding="20px"
                borderBottom="1px solid #ddd">
                {formatDate(row.created)}
              </Grid>
              <Grid
                className={styles.data}
                item
                xs={2}
                padding="20px"
                borderBottom="1px solid #ddd">
                {formatDate(row.expired)}
              </Grid>
            </Grid>
          )
        )
      })}
    </Grid>
  )
}

export default DataTable
