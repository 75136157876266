import { Amplify } from 'aws-amplify'
import { getCurrentUser, signIn } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Grid, TextField, Typography } from '@mui/material'

import IconMessage from '../../components/IconMessage/iconMessage'
import LoginImage from '../../components/LoginImage/loginImage'
import currentConfig from './awsConfig'
import styles from './login.module.css'

const Login = () => {
  // Config settings
  Amplify.configure(currentConfig)
  const navigate = useNavigate()

  // Use states
  const [showErrorMessage, setShowErrorMessage] = useState('')
  const [loadingButton, setLoadingButton] = useState(false)

  // Handlers
  const loginHander = async (event: any) => {
    event?.preventDefault()

    // Clean errors
    setShowErrorMessage('')
    setLoadingButton(true)

    // Data set
    const data = new FormData(event.currentTarget)
    const email = data.get('email')?.toString() ?? ''
    const password = data.get('password')?.toString() ?? ''

    // Validate data Input
    if (!email?.trim() ?? true) {
      setShowErrorMessage('El correo electrónico es requerido')
      return
    }

    if (!password?.trim()) {
      setShowErrorMessage('La contraseña es requerida')
      return
    }

    try {
      const { isSignedIn } = await signIn({
        username: email,
        password,
      })

      if (isSignedIn) {
        navigate('/panel')
      }
    } catch (error) {
      console.log(error)
      setShowErrorMessage('Usuario o contraseña incorrectos')
    }
    setLoadingButton(false)
  }

  // Use Effect
  useEffect(() => {
    const validateUser = async () => {
      try {
        const user = await getCurrentUser()
        if (user != null) {
          navigate('/panel')
        }
      } catch {
        /* empty */
      }
    }
    validateUser()
  }, [navigate])

  return (
    <Grid
      container
      className={styles.container}
      component="form"
      onSubmit={loginHander}
      alignItems="stretch"
      direction="row"
      display="flex"
      height="auto"
      padding="0">
      <Grid
        container
        item
        className={styles.formContainer}
        alignItems="center"
        direction="row"
        justifyContent="center"
        md={6}
        padding="40px 50px"
        xs={12}>
        <div>
          <Typography component="h1" variant="h1">
            Bienvenido
          </Typography>
          <Typography component="p" variant="subtitle1">
            Ingrese su correo y contraseña para accesar su cuenta
          </Typography>
          <Box padding="20px 0">
            <TextField
              autoComplete="email"
              autoFocus
              fullWidth
              id="email"
              label="Correo Electrónico"
              margin="normal"
              name="email"
              required
            />
            <TextField
              autoComplete="current-password"
              fullWidth
              id="password"
              label="Contraseña"
              margin="normal"
              name="password"
              required
              type="password"
            />
          </Box>
          <Box
            sx={{
              display: showErrorMessage !== '' ? 'block' : 'none',
              bgcolor: 'error.main',
              borderRadius: 1,
            }}
            margin="4px 0 24px"
            color="white">
            <IconMessage className={styles.error} text={showErrorMessage} icon="ErrorOutline" />
          </Box>
          <LoadingButton
            className={styles.submitButton}
            color="primary"
            fullWidth
            loading={loadingButton}
            size="large"
            type="submit"
            variant="contained">
            Iniciar Session
          </LoadingButton>
        </div>
      </Grid>
      <LoginImage />
    </Grid>
  )
}

export default Login
