import * as icons from '@mui/icons-material'

const Icon = ({
  name,
  className,
  width,
  height,
  ...props
}: {
  name: keyof typeof icons
  className?: string
  width?: string
  height?: string
}) => {
  const Component = icons[name]
  return <Component className={className} width={width} height={height} {...props} />
}

export default Icon
