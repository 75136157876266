import axios from 'axios'
import dayjs from 'dayjs'
import { useState } from 'react'

import { CheckCircleOutlineOutlined, ErrorOutline } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Box from '@mui/system/Box'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import styles from './createCode.module.css'

const CreateCode = () => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [open, setOpen] = useState(false)
  const [created, setCreated] = useState(false)

  // Form Values
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  const defaultPickerDay = () => {
    const date = dayjs(new Date()).add(1, 'month')
    return date
  }

  const createCodeHandler = async (event: any) => {
    event.preventDefault()


    // Clean Error on submit
    setLoadingButton(true)

    const data = new FormData(event?.currentTarget)
    const emailForm = email;
    const nameForm = name;
    const phoneForm = phone;
    const expirationDate = data.get('expirationDate')?.toString() ?? ''
    const body = { name: nameForm, email: emailForm, phone: phoneForm, expirationDate }

    try {
      const response = await axios({
        method: 'post',
        url: 'https://g6xcm6e3g8.execute-api.us-east-2.amazonaws.com/dev/createCode',
        data: JSON.stringify(body),
      })

      const sendEmailResult = await sendCodeEmail(email, name, response?.data?.code)

      if (sendEmailResult) {
        setOpen(true)
        setCreated(true)
        setName('');
        setEmail('');
        setPhone('');
      } else {
        setOpen(true)
        setCreated(false)
      }
    } catch (error) {
      setOpen(true)
      setCreated(false)
    } finally {
      setLoadingButton(false)
    }
  }

  const sendCodeEmail = async (email: string, name: string, code: string): Promise<boolean> => {
    const body = { name, email, code }
    try {
      await axios({
        method: 'post',
        url: 'https://g6xcm6e3g8.execute-api.us-east-2.amazonaws.com/dev/sendCodeEmail',
        data: JSON.stringify(body),
      })
      return true
    } catch (error) {
      return false
    }
  }

  return (
    <Grid container padding="20px">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box className={`${styles.modal} ${created ? styles.modalCreated : styles.modalError}`}>
          <p className={styles.modalText} id="child-modal-description">
            {created ? <CheckCircleOutlineOutlined /> : <ErrorOutline />}
            <span className={styles.modalMessage}>
              {created
                ? 'Código creado - Correo Enviado'
                : 'Error al crear el código favor intente nuevamente'}
            </span>
          </p>
        </Box>
      </Modal>
      <Box className={styles.headerTitle}>Crear un código</Box>
      <Grid
        item
        container
        onSubmit={createCodeHandler}
        component="form"
        direction="row"
        padding="20px 0">
        <TextField
          margin="normal"
          type="text"
          required
          fullWidth
          id="name"
          label="Nombre"
          name="name"
          autoComplete="name"
          value={name}
          onChange={e => setName(e.target.value)}
          autoFocus
        />
        <TextField
          margin="normal"
          type="email"
          required
          fullWidth
          id="email"
          label="Correo Electrónico"
          name="email"
          autoComplete="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          autoFocus
        />
        <TextField
          margin="normal"
          type="tel"
          required
          fullWidth
          id="phone"
          label="Teléfono"
          name="phone"
          autoComplete="phone"
          value={phone}
          onChange={e => setPhone(e.target.value)}
          autoFocus
        />
        <Box padding="16px 0" width="100%">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              className={styles.datePicker}
              label="Fecha de expiración"
              defaultValue={defaultPickerDay()}
              name="expirationDate"
            />
          </LocalizationProvider>
        </Box>
        <Box padding="16px 0" width="100%">
          <LoadingButton
            loading={loadingButton}
            size="large"
            className={styles.submitButton}
            type="submit"
            fullWidth
            variant="contained"
            color="success">
            Crear código y enviar por correo
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CreateCode
