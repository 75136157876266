import '@fontsource/red-hat-display'
import '@fontsource/red-hat-display/700.css'
import '@fontsource/roboto'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/inter'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'

import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#262161',
    },
    success: {
      main: '#4C8936',
    },
    error: {
      main: '#EF3F37',
    },
    secondary: {
      main: '#2196F3',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Red Hat Display',
      fontSize: '56px',
      color: '#5D5D5D',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Roboto',
      fontSize: '34px',
      fontWeight: 700,
      color: '#257EC2',
    },
    h3: {
      fontFamily: 'Roboto',
      fontSize: '48px',
      fontWeight: 500,
      lineHeight: '50px',
      letterSpacing: '0px',
      textAlign: 'center',
      color: '#5D5D5D',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: '18px',
      color: '#5D5D5D',
      fontWeight: 300,
      fontStyle: 'normal',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 300,
      fontStyle: 'normal',
    },
  },
})

export default theme
