import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import Main from './layout/Main/main'
import Form from './pages/Form/form'
import Home from './pages/Home/home'
import Login from './pages/Login/login'
import NoFound from './pages/NoPage/noPage'
import Panel from './pages/Panel/panel'
import theme from './theme'
import ResultsPage from './pages/Results/results'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route index element={<Home />} />
            <Route path="*" element={<NoFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/Panel" element={<Panel />} />
            <Route path="/form" element={<Form />} />
            <Route path="/results" element={<ResultsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
