import { fetchUserAttributes, getCurrentUser, signOut } from 'aws-amplify/auth'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Search } from '@mui/icons-material'
import { Button, Grid, InputAdornment, TextField } from '@mui/material'
import { Box } from '@mui/system'

import CreateCode from '../../components/CreateCode/createCode'
import DataTable from '../../components/DataTable/dataTable'
import Icon from '../../components/Icon/icon'
import NoResults from '../../components/NoResults/noResults'
import IDataRow from '../../interfaces/iDataRow'
import styles from './panel.module.css'

const Panel = () => {
  // Settings
  const navigate = useNavigate()

  // Use States
  const [user, setUser]: any = useState({})
  const [createCode, setCreateCode] = useState(false)
  const [codesRowLoad, setCodesRowLoad] = useState<Array<IDataRow>>([])
  const [logOut, setLogOut] = useState(false)
  const [noResults, setNoResults] = useState(false)

  // Handlers
  const searchHandler = (event: any) => {
    updateData(event?.target?.value)
  }

  const handleLogOut = () => {
    setLogOut(true)
  }

  const cancelLogOut = () => {
    setLogOut(false)
  }

  // Functions
  const updateData = (input: string) => {
    const updatedData = codesRowLoad?.map((item) => ({
      ...item,
      hide: !item.email.toLowerCase().includes(input.toLowerCase()),
    }))
    const temporalUpdatedData = updatedData
    setCodesRowLoad(updatedData)

    setNoResults(!temporalUpdatedData?.some((item) => !item.hide))
  }

  const closeSession = async () => {
    try {
      await signOut()
      navigate('/login')
    } catch {}
  }

  // Use Effect
  useEffect(() => {
    // Validate user
    const validateUser = async () => {
      try {
        const user = await getCurrentUser()
        if (user != null) {
          setUser(await fetchUserAttributes())
        } else {
          navigate('/login')
        }
      } catch {
        navigate('/login')
      }
    }

    const setRowsData = async () => {
      axios({
        method: 'get',
        url: 'https://g6xcm6e3g8.execute-api.us-east-2.amazonaws.com/dev/getCodes',
      })
        .then((response) => {
          if (response.data) {
            setCodesRowLoad(response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    // Set inicial load
    validateUser()
    setRowsData()
  }, [navigate])

  return (
    <Grid container direction="row">
      <Grid item xs={3} padding="20px 0" borderRight="2px solid #ddd">
        <Grid item container direction="row" alignItems="center">
          <Box
            alt="Bandera Logo"
            component="img"
            src="flag.png"
            sx={{
              width: 66,
            }}
          />
          <Box className={styles.logoTitle} paddingLeft="10px">
            Página Bandera
          </Box>
        </Grid>
        <Grid item container direction="column" paddingTop="50px">
          <Grid
            item
            container
            className={styles.leftPanelText}
            alignItems="center"
            paddingBottom="15px"
            paddingLeft="10px">
            <Icon name="HouseOutlined" className={styles.icon} width="24px" height="24px" />
            <Box paddingLeft="6px">Home</Box>
          </Grid>
          <Grid item container className={styles.leftPanelText} alignItems="center">
            <Button
              variant="text"
              onClick={() => {
                setCreateCode(false)
              }}>
              <span className={styles.leftPanelText}>
                <Icon name="Receipt" className={styles.icon} width="24px" height="24px" />
                <span className={styles.leftPanelTextButton}>Ver Códigos</span>
              </span>
            </Button>
          </Grid>
          <Grid item container className={styles.leftPanelText} alignItems="center">
            <Button
              variant="text"
              onClick={() => {
                setCreateCode(true)
              }}>
              <span className={styles.leftPanelText}>
                <Icon name="Receipt" className={styles.icon} width="24px" height="24px" />
                <span className={styles.leftPanelTextButton}>Crear Códigos</span>
              </span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignContent="baseline"
        alignItems="baseline"
        direction="row"
        justifyContent="space-between"
        xs={9}>
        <Grid item container borderBottom="2px solid #ddd" direction="row" padding="10px 0" xs={12}>
          <Grid item container alignItems="center" padding="0 20px" xs={4}>
            <Box>v1.0.0</Box>
          </Grid>
          <Grid item container direction="row" justifyContent="end" justifyItems="stretch" xs={8}>
            <Grid item>
              <Box textAlign="right">{user?.name}</Box>
              <Box textAlign="right">{user?.email}</Box>
            </Grid>
            <Grid item paddingLeft="20px">
              <Box
                className={styles.nameBox}
                alignItems="center"
                bgcolor="primary.main"
                borderRadius="50%"
                boxSizing="border-box"
                color="white"
                display="inline-block"
                height="100%"
                padding="5px 11px"
                position="relative"
                textTransform="capitalize">
                <Box onClick={handleLogOut}>{user?.name?.charAt(0)}</Box>

                {logOut && (
                  <Box
                    bgcolor="#ddd"
                    border="2px solid black"
                    padding="20px"
                    position="absolute"
                    right="0"
                    textAlign="right"
                    top="130%"
                    width="120px"
                    zIndex="10">
                    <Box padding="5px 0">
                      <Button variant="contained" color="error" onClick={closeSession}>
                        <Box color="white" width="100%" display="flex">
                          <Icon name="Logout" className={styles.icon} width="24px" height="24px" />
                          <Box>Log Out</Box>
                        </Box>
                      </Button>
                    </Box>
                    <Box padding="5px 0">
                      <Button variant="contained" color="primary" onClick={cancelLogOut}>
                        <Box color="white" width="100%" display="flex">
                          <Icon name="Close" className={styles.icon} width="24px" height="24px" />
                          <Box>Close</Box>
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {!createCode && (
          <Grid item container justifyContent="end" padding="25px 10px 0">
            <TextField
              label="Buscar por email"
              id="outlined-start-adornment"
              onChange={searchHandler}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </Grid>
        )}
        {createCode && <CreateCode />}

        {!createCode &&
          (codesRowLoad?.length > 0 && !createCode && !noResults ? (
            <DataTable dataTable={codesRowLoad} />
          ) : (
            <NoResults />
          ))}
      </Grid>
    </Grid>
  )
}

export default Panel
