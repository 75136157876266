import { useEffect, useState } from 'react'

import { InfoOutlined } from '@mui/icons-material'
import { Box, Checkbox, Grid } from '@mui/material'

import IMotivation from '../../interfaces/iMotivation'
import styles from './motivationBox.module.css'

const MotivationBox = ({
  motivation,
  formArray,
  setFormArray,
}: {
  motivation: IMotivation
  formArray: Array<IMotivation>
  setFormArray: any
}) => {
  const [motivationCheck, setMotivationCheck] = useState(false)
  const [showDescription, setShowDescription] = useState(false)

  const checkBoxHandler = (event: any) => {
    setMotivationCheck(event?.target?.checked ?? false)
    motivation.checked = event?.target?.checked ?? false

    if (event?.target?.checked ?? false) {
      setFormArray([...formArray, motivation])
    } else {
      const temporalFormArray = formArray?.filter((item: IMotivation) => item.id !== motivation.id)
      setFormArray(temporalFormArray)
    }
  }

  const descriptionHandler = () => {
    setShowDescription(!showDescription)
  }

  // Use Effect
  useEffect(() => {
    const isMotivationInFormArray = formArray.some((item) => item.id === motivation.id)
    setMotivationCheck(isMotivationInFormArray)
  }, [formArray, motivation.checked, motivation.id])

  return (
    <Grid item xs={12} md={3} className={styles.motivationLabel} padding="10px">
      <Grid
        item
        container
        border="1px solid #EFEFEF"
        boxShadow="0px 0px 5px 2px #00000005"
        bgcolor={motivationCheck ? '#D3ECFF' : ''}
        borderRadius="5px"
        padding="10px 10px 10px 0"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item>
          <Checkbox
            checked={motivationCheck}
            value={motivation.id}
            sx={{ '&.Mui-checked': { color: '#1976D2' } }}
            onChange={checkBoxHandler}
          />
          {motivation?.name}
        </Grid>
        <InfoOutlined
          width={22}
          height={22}
          onClick={descriptionHandler}
          sx={{ ':hover': { cursor: 'pointer' } }}
        />
        <Box width="100%" padding="11px" display={showDescription ? 'block' : 'none'}>
          {motivation.description}
        </Box>
      </Grid>
    </Grid>
  )
}

export default MotivationBox
