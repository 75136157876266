import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'us-east-2_tJVMwL6Sf',
      userPoolClientId: '3dpo8unvgi7t9rid6ihuvjoemd',
      identityPoolId: 'us-east-2:68b589ea-bf05-4185-baff-213a108c31fe',
    }
  }
});

export default Amplify.getConfig();