import './index.css'

import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
    <App />
  </StrictMode>,
)
