import { useEffect, useState } from 'react'

import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'

import IconMessage from '../../components/IconMessage/iconMessage'
import LoginImage from '../../components/LoginImage/loginImage'
import styles from './home.module.css'
import axios from 'axios'
import IFormCode from '../../interfaces/iFormCode'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const navigate = useNavigate()
  // Use states
  const [showErrorMessage, setShowErrorMessage] = useState('')
  const [completeForm, setCompleteForm] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [codeInput, setCodeInput] = useState('')

  // Handlers
  const sendCodeHandler = async (event: any) => {
    event?.preventDefault()

    // Clean errors
    setShowErrorMessage('')
    setLoadingButton(true)

    // Data set
    const data = new FormData(event.currentTarget)
    const code = data.get('code')?.toString() ?? ''

    // Validate data Input
    if (!code?.trim() ?? true) {
      setShowErrorMessage(
        'Código invalido, puede adquirir un código escribiendo a +(506) 8895 - 3294',
      )
      return
    }

    const body = { code }

    axios({
      method: 'post',
      url: 'https://g6xcm6e3g8.execute-api.us-east-2.amazonaws.com/dev/getCode',
      data: JSON.stringify(body),
    })
      .then((response) => {
        const codeData: IFormCode = response.data

        // Validate status
        const today = moment()
        const expired = moment(codeData?.expired)

        if (today.isAfter(expired) || codeData.status === 3) {
          setShowErrorMessage(
            `El código expiró en ${expired.format('DD-MM-YYYY')}, puede adquirir un nuevo código escribiendo a +(506) 8895 - 3294`,
          )
        } else if (codeData?.status === 2) {
          setCompleteForm(true)
          sessionStorage.setItem('userData', JSON.stringify(codeData))
          setShowErrorMessage(
            `El código ingresado ya fue utilizado, si desea ver el resultado de esta página bandera presione el botón "Ver resultado", o bien si desea obtener un nuevo código por favor envíe un mensaje al +(506) 8532-6132.`,
          )
        } else {
          sessionStorage.setItem('userData', JSON.stringify(codeData))
          navigate('/form')
        }

        setLoadingButton(false)
      })
      .catch((error) => {
        setShowErrorMessage(
          'Código invalido, puede adquirir un código escribiendo a +(506) 8895 - 3294',
        )
        setLoadingButton(false)
      })
  }

  // Handler results page
  const handleSendResults = () => {
    navigate('/results')
  }

  // Handler Code Input
  const handlerCodeInput = (event: any) => {
    const inputValue = event.target.value.toUpperCase()
    setCodeInput(inputValue)
  }

  // Use Effect
  useEffect(() => {}, [])

  return (
    <Grid
      container
      className={styles.container}
      component="form"
      onSubmit={sendCodeHandler}
      alignItems="stretch"
      direction="row-reverse"
      display="flex"
      height="auto"
      padding="0">
      <LoginImage />
      <Grid
        container
        item
        className={styles.formContainer}
        alignItems="center"
        direction="row"
        justifyContent="center"
        md={6}
        sx={{
          marginTop: { xs: '-85px', sm: '0' },
          backgroundColor: { sm: 'white' },
          padding: { xs: '10px 25px', sm: '10px 50px' },
        }}
        xs={12}>
        <Grid item container direction="column" xs={12} alignItems="center">
          <Box alt="Bandera Logo" component="img" src="logo.png" width="150px" padding="15px 0" />
          <Typography component="h1" variant="h1" textAlign="center">
            Bienvenido
          </Typography>
          <Typography component="p" variant="subtitle1" textAlign="center">
            Ingrese el código
          </Typography>
          <Box padding="20px 0" width="100%">
            <TextField
              autoComplete="código"
              autoFocus
              fullWidth
              id="code"
              label="Código"
              margin="normal"
              name="code"
              value={codeInput}
              onChange={handlerCodeInput}
              required
            />
          </Box>
          <Box
            sx={{
              display: showErrorMessage !== '' ? 'block' : 'none',
              bgcolor: completeForm ? ' #0288D1' : 'error.main',
              borderRadius: 1,
            }}
            margin="4px 0 24px"
            color="white">
            <IconMessage className={styles.error} text={showErrorMessage} icon="ErrorOutline" />
          </Box>
          {!completeForm && (
            <LoadingButton
              className={styles.submitButton}
              color="primary"
              fullWidth
              loading={loadingButton}
              size="large"
              type="submit"
              variant="contained">
              Completar mi página bandera
            </LoadingButton>
          )}
          {completeForm && (
            <Grid item container>
              <Grid item md={6} padding="0 5%">
                <Button
                  className={styles.submitButton}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  onClick={handleSendResults}
                  variant="contained">
                  Ver Resultado
                </Button>
              </Grid>
              <Grid item md={6} padding="0 5%">
                <Button
                  className={styles.submitButton}
                  fullWidth
                  size="large"
                  type="button"
                  onClick={() => {
                    setCompleteForm(false)
                    setShowErrorMessage('')
                  }}
                  sx={{ bgcolor: 'white', border: '1px solid #7F7D7D', color: '#7F7D7D' }}
                  variant="outlined">
                  Aceptar
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Home
