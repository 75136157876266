import axios from 'axios'
import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'

import FormHeader from '../../components/FormHeader/formHeader'

import IMotivation from '../../interfaces/iMotivation'
import { useNavigate } from 'react-router-dom'
import IFormCode from '../../interfaces/iFormCode'
import Results from '../../components/Results/results'

const ResultsPage = () => {
  const navigate = useNavigate()
  // Use States

  const [firstMotivationLlist, setFirstMotivationLlist] = useState<Array<IMotivation>>()
  const [userData, setUserData] = useState<IFormCode>()
  const [loadedData, setLoadedData] = useState(false)

  // Use Effect
  useEffect(() => {
    const userDataSaved = sessionStorage.getItem('userData')
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get('code')

    const getMotivationList = (idCode: string) => {
      axios({
        method: 'get',
        params: {
          idCode: idCode,
        },
        url: 'https://g6xcm6e3g8.execute-api.us-east-2.amazonaws.com/dev/getResults',
      })
        .then((response) => {
          if (response.data) {
            const formData = JSON.parse(response.data?.resultData)

            setFirstMotivationLlist(formData)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    if (!userDataSaved && !code) {
      navigate('/')
    }

    if (userDataSaved) {
      const savedUserData = JSON.parse(userDataSaved ?? '') ?? {}
      setUserData(savedUserData)

      if (loadedData) {
        getMotivationList(savedUserData?.idCode)
      }
    } else if (code) {
      const body = { code }

      console.log('body', body)

      axios({
        method: 'post',
        url: 'https://g6xcm6e3g8.execute-api.us-east-2.amazonaws.com/dev/getCode',
        data: JSON.stringify(body),
      })
        .then((response) => {
          const codeData: IFormCode = response.data

          if (codeData?.status !== 2) {
            navigate('/')
          }

          setUserData(codeData)

          if (loadedData) {
            getMotivationList(codeData.idCode.toString())
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    setLoadedData(true)
  }, [navigate, loadedData, setUserData, setFirstMotivationLlist])

  return (
    <Grid item>
      <FormHeader userName={userData?.name ?? ''} />
      {firstMotivationLlist && <Results motivation={firstMotivationLlist ?? []} saveFlag={false} />}
    </Grid>
  )
}

export default ResultsPage
