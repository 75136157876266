import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

const FormHeader = ({ userName }: { userName: string }) => {
  return (
    <Grid
      container
      item
      xs={12}
      position="relative"
      sx={{
        justifyContent: { xs: 'left', md: 'center' },
        padding: { xs: '30px 0', md: '50px 0' },
      }}>
      <Box width="100%" height="65px" bgcolor="#45A5EF" textAlign="right" paddingRight="30px">
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontSize: { xs: '24px', md: '34px' },
            fontWeight: '400',
            color: 'white',
            paddingTop: { xs: '13px', md: '5px' },
          }}>
          {userName}
        </Typography>
      </Box>
      <Box
        position="absolute"
        top="18px"
        alignItems="center"
        component="img"
        src="logo.png"
        sx={{
          width: { xs: 100, md: 130 },
          top: { xs: '12px', md: '18px' },
          left: { xs: '20px', md: '50% ' },
          right: { md: '50%' },
          transform: { md: 'translate(-50%)' },
        }}
        zIndex={2}
      />
    </Grid>
  )
}

export default FormHeader
