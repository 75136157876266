import { useEffect, useState } from 'react'

import { Box, Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import IMotivation from '../../interfaces/iMotivation'
import styles from './motivationSort.module.css'

const MotivationSort = ({
  motivation,
  formArray,
  setFormArray,
}: {
  motivation: IMotivation
  formArray: Array<IMotivation>
  setFormArray: any
}) => {
  const [currentValue, setCurrentValue] = useState('0')

  const handleRadioChange = (event: any) => {
    const option = event?.target?.value ?? 0

    // Reset value
    const resetArray = formArray.map((ele) => {
      if (ele.sortOrder !== undefined && ele.sortOrder === option) {
        ele.sortOrder = '0'
      }
      return ele
    })

    const temporalFormArray = resetArray.map((ele) => {
      if (ele.id === motivation.id) {
        ele.sortOrder = option
      }
      return ele
    })

    setFormArray(temporalFormArray)
  }

  // Use Effect
  useEffect(() => {
    setCurrentValue(motivation?.sortOrder ?? '0')
  }, [motivation?.sortOrder])

  return (
    <Grid item xs={12} md={4} className={styles.motivationLabel} padding="10px">
      <Grid
        item
        container
        border="1px solid #EFEFEF"
        boxShadow="0px 0px 5px 2px #00000005"
        borderRadius="5px"
        padding="20px"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item>{motivation?.name}</Grid>
        <Box width="100%" padding="15px 0">
          {motivation.description}
        </Box>
        <Box>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={currentValue}
              onChange={handleRadioChange}>
              <FormControlLabel value="1" control={<Radio color="secondary" />} label="A" />
              <FormControlLabel value="2" control={<Radio color="secondary" />} label="B" />
              <FormControlLabel value="3" control={<Radio color="secondary" />} label="C" />
              <FormControlLabel value="4" control={<Radio color="secondary" />} label="D" />
              <FormControlLabel value="5" control={<Radio color="secondary" />} label="E" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  )
}

export default MotivationSort
