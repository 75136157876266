import axios from 'axios'
import parse from 'html-react-parser'
import { useEffect, useState } from 'react'

import { NavigateBeforeOutlined, NavigateNextOutlined } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { Box } from '@mui/system'

import FormHeader from '../../components/FormHeader/formHeader'
import MotivationBox from '../../components/MotivationBox/motivationBox'
import MotivationSlider from '../../components/MotivationSlider/motivationSlider'
import MotivationSort from '../../components/MotivationSort/motivationSort'
import Results from '../../components/Results/results'
import IMotivation from '../../interfaces/iMotivation'
import styles from './form.module.css'
import { useNavigate } from 'react-router-dom'
import IFormCode from '../../interfaces/iFormCode'

const Form = () => {
  const navigate = useNavigate()
  // Use States
  const [currentStep, setCurrentStep] = useState(1)
  const [motivationLlist, setMotivationList] = useState<Array<IMotivation>>()
  const [firstMotivationLlist, setFirstMotivationLlist] = useState<Array<IMotivation>>()
  const [userData, setUserData] = useState<IFormCode>()

  // Data
  const stepParagrahps = [
    {
      paragraph1:
        'Primero elegir honestamente todas las motivaciones que creas que te describen. Es importante que tengas una perspectiva general, porque todas las personas <strong>PUEDEN</strong> tener cualquiera de estas motivaciones.',
      paragraph2:
        'Por ejemplo: ¿Puedo tener “buen genio”? Por supuesto. Pero es “buen genio” una buena descripción general de ¿quién soy? <strong>¡QUE VA!</strong> Entonces, no debes elegir “buen genio”. Si no estas seguro del significado o de la frase, selecciona el icono y una definición sencilla aparecerá para cada motivación.',
    },
    {
      paragraph1:
        'Ahora, debes dar una puntuación a cada característica del 1 al 10 de acuerdo según te haga sentir. Si la característica te hace sentir muy bien, dale una puntuación de 10. Si esta característica te deja neutral dale una de 1.',
      paragraph2:
        '<strong>Se debe tener al menos 5 motivaciones con un calificación mayor o igual a 7</strong>',
    },
    {
      paragraph1:
        'Finalmente, de las características que escogiste en el paso 2, <strong>selecciona las 5 que consideras son las más importantes, donde A es la de mayor importancia y E la de menor.</strong> Revisa con cuidado antes de seleccionar el orden.',
      paragraph2:
        '<strong> Se muestran todas aquellas características o motivaciones que hayan tenido puntuación mayor a 7.</strong>',
    },
  ]

  const nextButtonHandler = (): boolean => {
    if (firstMotivationLlist) {
      if (currentStep === 1 && firstMotivationLlist?.length > 6) {
        return false
      } else if (currentStep === 2 && firstMotivationLlist?.length > 4) {
        let count = firstMotivationLlist.filter((ele) => {
          return ele.calification && ele.calification > 6
        }).length

        if (count >= 5) {
          return false
        }
      } else if (currentStep === 3) {
        const chooseOrder = firstMotivationLlist?.filter(
          (ele) => ele.sortOrder !== '0' && ele.sortOrder !== undefined,
        )
        return chooseOrder.length < 5
      }
    }

    return true
  }

  // Use Effect
  useEffect(() => {
    const userDataSaved = sessionStorage.getItem('userData')

    if (!userDataSaved) {
      navigate('/')
    } else {
      setUserData(JSON.parse(userDataSaved) ?? {})

      const getMotivationList = () => {
        axios({
          method: 'get',
          url: 'https://g6xcm6e3g8.execute-api.us-east-2.amazonaws.com/dev/getMotivations',
        })
          .then((response) => {
            if (response.data) {
              setMotivationList(response.data)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
      getMotivationList()
      setCurrentStep(1)
    }
  }, [setMotivationList, navigate])

  // update state
  const updateFirstMotivationList = (motivation: Array<IMotivation>) => {
    motivation?.sort((a, b) => a.name.localeCompare(b.name))
    setFirstMotivationLlist(motivation)
  }

  return (
    <Grid item>
      <FormHeader userName={userData?.name ?? ''} />
      {currentStep === 4 && <Results motivation={firstMotivationLlist ?? []} saveFlag={true} />}
      {currentStep < 4 && (
        <Grid
          item
          className={currentStep <= 3 ? styles.bgImage : ''}
          container
          sx={{ padding: { xs: '50px 20px 0', md: '50px 45px 0' } }}
          marginTop="-50px">
          <Typography
            component="h1"
            variant="h1"
            sx={{
              fontSize: { xs: '36px', md: '56px' },
              paddingTop: { xs: '40px', md: '0' },
              paddingBottom: { sx: '0', md: '20px' },
            }}>
            Paso {currentStep}
          </Typography>
          {currentStep <= 3 && (
            <Box
              component="p"
              className={styles.stepParagraph}
              sx={{ fontSize: { xs: '18px', md: '24px' } }}>
              {parse(stepParagrahps[currentStep - 1].paragraph1)}
            </Box>
          )}
          {currentStep <= 3 && (
            <Box
              component="p"
              className={styles.stepParagraph}
              sx={{ fontSize: { xs: '18px', md: '24px' }, paddingBottom: { md: '50px' } }}>
              {parse(stepParagrahps[currentStep - 1].paragraph2)}
            </Box>
          )}
          {currentStep === 1 &&
            (motivationLlist ? (
              <Grid container item bgcolor="white" border="1px solid #ECECEC" padding="5px">
                {motivationLlist &&
                  motivationLlist?.map((motivation: IMotivation, index) => {
                    return (
                      <MotivationBox
                        motivation={motivation}
                        formArray={firstMotivationLlist ?? []}
                        setFormArray={updateFirstMotivationList}
                        key={index}
                      />
                    )
                  })}
              </Grid>
            ) : (
              <Grid
                container
                item
                bgcolor="white"
                border="1px solid #ECECEC"
                justifyContent="center"
                padding="5px">
                <Box sx={{ width: '100%' }}>
                  <LinearProgress color="secondary" />
                </Box>
              </Grid>
            ))}
          {currentStep === 2 && (
            <Grid container item bgcolor="white" border="1px solid #ECECEC" padding="5px">
              {firstMotivationLlist &&
                firstMotivationLlist?.map((motivation: IMotivation, index) => {
                  return (
                    <MotivationSlider
                      motivation={motivation}
                      formArray={firstMotivationLlist ?? []}
                      setFormArray={updateFirstMotivationList}
                      key={index}
                    />
                  )
                })}
            </Grid>
          )}
          {currentStep === 3 && (
            <Grid container item bgcolor="white" border="1px solid #ECECEC" padding="5px">
              {firstMotivationLlist &&
                firstMotivationLlist?.map((motivation: IMotivation, index) => {
                  return (
                    ((motivation?.calification && motivation.calification > 6) ?? false) && (
                      <MotivationSort
                        motivation={motivation}
                        formArray={firstMotivationLlist ?? []}
                        setFormArray={updateFirstMotivationList}
                        key={index}
                      />
                    )
                  )
                })}
            </Grid>
          )}
        </Grid>
      )}
      {currentStep <= 3 && (
        <Grid
          container
          item
          sx={{ padding: { xs: '50px 10px', md: '70px' } }}
          justifyItems="center"
          alignItems="center"
          justifyContent="center">
          <Box>
            <Button
              variant="text"
              className={styles.pagination}
              sx={{ color: currentStep === 1 ? '#00000061' : '#2196F3' }}
              onClick={(event) => {
                event.preventDefault()
                setCurrentStep(currentStep - 1)
                setTimeout(() => {
                  if (window) {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }
                }, 100)
              }}
              disabled={currentStep === 1}>
              <NavigateBeforeOutlined sx={{ width: '16px', height: '18px', padding: '0 5px' }} />
              Anterior
            </Button>
          </Box>
          <Box className={styles.pagination} padding="0 40px">
            {currentStep}/3
          </Box>
          <Box>
            <Button
              variant="text"
              className={styles.pagination}
              disabled={nextButtonHandler()}
              sx={{ color: nextButtonHandler() ? '#00000061' : '#2196F3' }}
              onClick={(event) => {
                setCurrentStep(currentStep + 1)
                event.preventDefault()
                setTimeout(() => {
                  if (window) {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }
                }, 100)
              }}>
              Siguiente
              <NavigateNextOutlined sx={{ width: '16px', height: '18px', padding: '0 5px' }} />
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default Form
